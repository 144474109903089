import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
       کار آزاد ایرانیان در حال راه اندازی مجدد می باشد
      </header>
    </div>
  );
}

export default App;
